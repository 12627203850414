import React from 'react'

// Styles
import { KontaktFormHolder } from "./KontaktForm.styles"

const Kontaktform = () => (
    <KontaktFormHolder>
        <form 

            name="Kontakt Form"
            method="POST"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"        
        >
         <input type="hidden" name="form-name" value="Kontakt Form" />
            <input type="hidden" name="bot-field" />

          <div className="mina-uppgifter">
            <div className="row-del">
                <div className="new">
                    <input type="text" name="Kontaktperson" />
                    <label for="Kontaktperson" className="label-kontaktperson">
                        <span className="content-input">Kontaktperson</span>
                    </label>
                </div>

                <div className="new">
                    <input type="email" name="Epost" />
                    <label for="epost" className="label-kontaktperson">
                        <span className="content-input">Epost</span>
                    </label>
                </div>

                <div className="new">
                    <input type="number" name="Telefon" />
                    <label for="Telefon" className="label-kontaktperson">
                        <span className="content-input">Telefon</span>
                    </label>
                </div>

                
                
                
            </div>
            
            <div className="row-del">
                
                    <textarea
                        placeholder="Meddelande"
                        name="oppettider"
                        id="oppettider"
                    ></textarea>        
            </div>
            </div> {/* end mina-uppgifter */}

            <div className="form-end">
                 
                <div className="gdpr">
                <input type="checkbox" name="checkbox_kon" required 
                value="
                Genom att jag har skickat detta meddelande samtycker jag till att 
                ni tar del av de personuppgifter jag har valt att delge.
                "/>
                
                <label>
                    restaurangerboras.se lagrar bokningshistorik och personuppgifter som 
                    kunden lämnar och behandlar dessa uppgifter i enlighet med gällande 
                    GDPR (General Data Protection Regulation). Genom att skicka offertförfågan 
                    godkänner du användaravtalet.
                </label>
                </div>
                <input className="button " type="submit" value="Skicka" />
            </div>
      </form>
    </KontaktFormHolder>

)

export default Kontaktform
