import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "components/Layout"
import Img from "gatsby-image"

import SEO from "components/SEO"
import Kontakt from "components/Kontakt"
import AnnonsForm from "components/AnnonsForm"
import HeaderRestaurant from "../components/HeaderRestaurant"
import Footer from "components/Footer"


const Page = ({ data }) => {
  const page = data.markdownRemark 


  return (
    <Layout> 
      <HeaderRestaurant />
      <SEO title={page.frontmatter.seotitle} description={page.frontmatter.seodescription}/>
      {/* <Img className="page-hero-img" fluid={page.frontmatter.heroimg.childImageSharp.fluid} alt={page.frontmatter.title}/> */}
      <main className="subpage-content">
      <p>Restauranger<span>Boras.se</span></p>
        <h1>{page.frontmatter.titleh1}</h1>
        <div className="subpage-text">
          <h2>{page.frontmatter.titleh2}</h2>
          <div dangerouslySetInnerHTML={{ __html: page.html }} /> 
        </div>
        
         {page.frontmatter.slug === "kontakt" && 
          <>
           <Kontakt />      
         </> }

         {page.frontmatter.slug === "annons" && 
          <>
           <AnnonsForm />     
         </> }
      </main>
      <Footer />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html 
      frontmatter { 
        titleh1
        titleh2
        date
        slug 
        title
        seotitle
        seodescription
        heroimg {
          childImageSharp {
            fluid(maxWidth:1600) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
