import styled from "styled-components"

export const AnnonsFormHolder = styled.div`
background:#222629;
width:100%;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

.mina-uppgifter {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:flex-start;
    margin-bottom:70px;
    width: calc(100% - 15px);
    margin: 0 auto;
    padding-top: 0px;
    @media screen and (min-width: 768px) {
        flex-direction:row;
        justify-content:space-around;
    }

    .row-del {
        width:90%;
        @media screen and (min-width: 768px) {
        width:40%;

        }
        input {
            height:40px;
            border:none;
            border-radius:5px;
            width:90%;
            margin-bottom:15px;
            padding-left:15px;
            color:white;
            background: black;

            &:focus {
                outline:1px solid green;;
            }
        }
        label {
            font-family: 'Nunito', sans-serif;

        }
        textarea {
            width: calc(100% - 25px);
            margin-left: 10px;
            border-radius: 5px;
            height: 152px;
            border: none;
            padding-left: 25px;
            padding-top: 5px;
            font-family: 'Nunito',sans-serif;
            color: white;
            background: black;
            margin-bottom:35px;

            &::placeholder {
                color:${props => props.theme.colors.prime};
                font-size: .85rem;
                letter-spacing: 1px;

            }
            &:focus {
                outline: none;
            }
        }
    }
}

.form-end {
    padding:5px;
    text-align:left;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    
    .gdpr {
        width: 90%;
        margin: 0 auto;
        padding-top: 35px;
        font-family: 'Nunito', sans-serif;
        font-size:.8rem;
        padding-bottom:35px;
        color:white;

        input {
            margin-right:10px;
            
        }
    }


    .button {
        background:${props => props.theme.colors.prime};
        color:black;
        transition:.3s;
        padding:7px 15px;
        border: 1px solid ${props => props.theme.colors.prime};
        font-family:'Nunito', sans-serif;
        text-transform:uppercase;
        text-align:center;
        border-radius:50px;
        max-width:300px;
        width:50%;

        :hover {
            background:transparent;
            border:1px solid ${props => props.theme.colors.prime};
            color:${props => props.theme.colors.prime};
            cursor:pointer;
        }
    }
}


.mina-uppgifter {
    .row-del {
        .new {
            width: 100%;
            height:70px;
            position: relative;
            margin-bottom:35px;
            text-align: left;
            margin-left:10px;
            overflow: hidden;
            
            input {
                width: calc(100% - 25px);
                height: 100%;
                margin:0;
                font-size:1.2rem;
                padding:0;
                padding-left:25px;

                &::focus {
                    outline: none;
                    .label-kontaktperson {
                        transform:translateY(-150%);
                    }
                }
            }

            label {
                position:absolute;
                bottom: 0px;
                color: white;
                height: 100%;
                width: 100%;
                left: 0%;
                pointer-events: none;
                text-align: left;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    transform:translateX(-101%);
                    bottom: 0px;
                    height: 100%;
                    width: 100%;
                    border-bottom: 1px solid ${props => props.theme.colors.prime};
                    transition: all .3s ease;
                }

                .content-input {
                    position: absolute;
                    bottom: 25px;
                    left: 25px;
                    font-size: 1.3rem;
                    color: gray;
                    transition: all .3s ease;
                }
            }

        }
    }
}

.new input:focus + label .content-input,
.new input:valid + label .content-input
{
    transform: translateY(-150%);
    color: ${props => props.theme.colors.prime} !important;
    font-size: .85rem !important;
}


.new input:focus + label::after
{
    transform: translateX(0%) !important;
    
}

`